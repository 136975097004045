import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";

import PageHeader from "components/Common/PageHeader";
import Page from "./Page";

const Videos = ({ pageContext: { videos, pageIndex, perPage } }) => {
  const { videos: page } = useSiteData().pages;

  return (
    <Root className="body-width">
      <PageHeader page="videos" />
      <Page
        collection={videos}
        page={page}
        perPage={perPage}
        pageIndex={pageIndex}
      />
    </Root>
  );
};

export default Videos;
