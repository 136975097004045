import React from "react";
import { Root } from "./style";

import { Link } from "gatsby";

import chunk from "lodash/chunk";

import Paginator from "components/Common/Paginator";
import JoinTheCommunities from "components/Common/JoinTheCommunities";
import SiteImage from "components/Common/SiteImage";

import { toResourceURL } from "utils";
import vars from "variables";

const Page = ({ collection, page, perPage, pageIndex }) => {
  const resourceURL = toResourceURL(vars.pages.videos);
  const pages = chunk(collection, perPage).map((group) => ({
    items: group,
  }));

  pageIndex || (pageIndex = 1);
  const { items } = pages[pageIndex - 1];

  return (
    <Root>
      <ul className="items">
        {items.slice(0, 6).map((video) => (
          <li key={video.id}>
            <Link to={resourceURL(video)}>
              <SiteImage image={video.image} />
            </Link>
            <strong>
              <Link to={resourceURL(video)}>{video.title}</Link>
            </strong>
            <p>{video.intro}</p>
            <Link className="more" to={resourceURL(video)}>
              Learn more
            </Link>
          </li>
        ))}
      </ul>
      <Paginator
        path={page.path}
        perPage={perPage}
        pageIndex={pageIndex}
        totalCount={collection.length}
      />
      <JoinTheCommunities />
      <ul className="items">
        {items.slice(6, 9).map((video) => (
          <li key={video.id}>
            <Link to={resourceURL(video)}>
              <SiteImage image={video.image} />
            </Link>
            <strong>
              <Link to={resourceURL(video)}>{video.title}</Link>
            </strong>
            <p>{video.intro}</p>
            <Link className="more" to={resourceURL(video)}>
              Learn more
            </Link>
          </li>
        ))}
      </ul>
      <Paginator
        path={page.path}
        perPage={perPage}
        pageIndex={pageIndex}
        totalCount={collection.length}
      />
    </Root>
  );
};

export default Page;
