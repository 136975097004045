import styled from "styled-components";

import vars from "variables";

import arrow from "assets/Videos/Page/right-blue.svg";

const Root = styled.div`
  ul.items {
    margin: 50px 0;
  }

  ul.items li {
    margin-bottom: 40px;
    flex: 0 0 calc(33.3% - 18px);
  }

  ul.items li img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  ul.items li strong {
    display: block;
    text-transform: uppercase;
  }

  ul.items li p {
    height: 60px;
    overflow: hidden;
  }

  a.more {
    padding-right: 20px;
    font-weight: bold;
    text-transform: uppercase;
    background: url(${arrow}) no-repeat center right;
  }

  ${vars.desktop.mediaQuery} {
    ul {
      display: flex;
    }

    ul.items {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    ul.items li img {
      height: 280px;
    }

    ul.items li strong {
      margin: 15px 0 3px;
      font-size: 0.8rem;
    }

    ul.items li p {
      margin-bottom: 35px;
      color: ${vars.colors.grey};
      line-height: 140%;
    }

    a.more {
      font-size: 0.8rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    ul.items li strong {
      margin: 18px 0 15px 0;
      font-size: 0.85rem;
    }

    li p {
      margin-bottom: 25px;
    }

    .join-the-communities {
      margin-top: -35px;
    }
  }
`;

export { Root };
